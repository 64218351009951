
// Fonts
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap-sass/assets/stylesheets/bootstrap";

// mp035: Bootstrap-select
@import "node_modules/bootstrap-select/sass/bootstrap-select.scss";

// mp035 style nav-tabs
.nav-tabs > li.active > a{
    border-color: $brand-primary;
    border-bottom-color: transparent;
    background-color: $navbar-default-bg;
}

//mp035 add styling for twitter-typeahead
@mixin typeahead-active() {
    // mimics  @extend .dropdown-menu > .active > a;
    color: $dropdown-link-active-color;
    text-decoration: none;
    outline: 0;
    background-color: $dropdown-link-active-bg;
  }
  
  //https://github.com/corejavascript/typeahead.js/blob/master/doc/jquery_typeahead.md#class-names
  span.twitter-typeahead {

    width:100%; // mp035 added this so that the text input is full width (for farmers forms)
  
    // this is the suggested matches dropdown
    .tt-menu {
      @extend .dropdown-menu;
    }
  
    .tt-hint {
      color: #999
    }
  
    // Added to suggestion elements.
    .tt-suggestion {
  
      // mimic .dropdown-menu > li > a
      padding: 3px 20px;
      line-height: $line-height-base;
  
      // Added to suggestion element when menu cursor moves to said suggestion.
      &.tt-cursor {
        @include typeahead-active;
      }
  
      // Hover/focus on suggestion
      &:hover,
      &:focus {
        @include typeahead-active;
      }
  
      p {
        margin: 0;
      }
    }
  
    .input-group & {
      display: block !important;
      .tt-dropdown-menu {
        top: 32px !important;
      }
    }
    .input-group.input-group-lg & {
      .tt-dropdown-menu {
        top: 44px !important;
      }
    }
    .input-group.input-group-sm & {
      .tt-dropdown-menu {
        top: 28px !important;
      }
    }
  }

  // == end twitter-typeahead
  // ========================